.demi-cards {
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  gap: 10px;
  height: 100vh;
  padding: 10px;
}

.row {
  display: grid;
  gap: 10px;
}

.div-1 {
  grid-template-columns: 1fr;
}

.div-2 {
  grid-template-columns: 1fr 1fr;
}

.div-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.card {
  height: 100%;
  border-radius: 8px;
}