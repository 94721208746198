/* subscription.css */
.subscription-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.subscription-iframe {
  flex: 1;
  border: none;
  width: 100%;
  height: 100%;
}
