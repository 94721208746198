html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
}