.card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.container{
    margin-top: 1.3em;

}

.card {
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  cursor: pointer;
}

.link {
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
  transition: transform 0.3s ease;
}

.subscription-card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #5A54A4!important;
}

.card-logo {
  width: 90px;
  height: 40px;
  object-fit: contain;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
}
.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}
@media (max-width: 900px) {
  p{
    font-size: 1em!important;
    margin: 0!important;;
  
}
}