.layout-container {
  display: flex;
  min-height: 100vh;
  flex-wrap: nowrap;
}

.layout-container.top {
  flex-direction: column;
}

.layout-container.bottom {
  flex-direction: column-reverse;
}

.layout-container.left {
  flex-direction: row;
}

.layout-container.right {
  flex-direction: row-reverse;
}

.navigation {
  background-color: #f0f0f0;
  padding: 20px;
  display: flex;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.navigation.top,
.navigation.bottom {
  width: 100%;
  min-height: 71px!important;
}

.navigation.left,
.navigation.right {
  height:100vh;
  min-width: 208px !important;
}

.nav-content {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
nav{
  padding: 0!important;
}
.nav-content.vertical {
  flex-direction: column;
}

.nav-content.horizontal {
  flex-direction: row;
  align-items: center;
}

.nav-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  height: fit-content;
  gap: 0.3em;
  align-items: flex-start;
}


.nav-content.vertical .nav-menu {
  flex-direction: column;
}

.nav-content.horizontal .nav-menu {
  flex-direction: row;
  align-items: center;
}

.nav-menu li {
  margin-bottom: 15px;
  white-space: nowrap; /* Prevent text wrapping */
}

.nav-logo {
  font-weight: bold;
  font-size: 1.5em;
  color: #333;
padding-top: 0.4em;
cursor: pointer;

}
.nav-link{
  margin-top: 0.5em;
}
.nav-link:hover{
  font-weight: 900;
}
.nav-links a {
  text-decoration: none;
  color: #333;
  cursor: pointer!important;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #007bff;
}

.logout-button {
  width: 100%;
  font-weight: 600;
  color: white;
  padding: 0.3em!important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1em;
  transition: background-color 0.3s ease;
}


.resizing-handle-right {
  position: absolute;
  top: 0;
  right: -5px;
  bottom: 0;
  width: 10px;
  cursor: col-resize;
}

.resizing-handle-left {
  position: absolute;
  top: 0;
  left: -5px;
  bottom: 0;
  width: 10px;
  cursor: col-resize;
}

.resizing-handle-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
  height: 10px;
  cursor: row-resize;
}

.resizing-handle-top {
  position: absolute;
  left: 0;
  right: 0;
  top: -5px;
  height: 10px;
  cursor: row-resize;
}

.resizing-handle-right:hover,
.resizing-handle-left:hover,
.resizing-handle-bottom:hover,
.resizing-handle-top:hover,
.resizing-handle-right:active,
.resizing-handle-left:active,
.resizing-handle-bottom:active,
.resizing-handle-top:active {
  background-color: rgba(0, 0, 0, 0.1);
}

.main-content {
  flex-grow: 1;
  overflow: auto;
  /* padding: 20px; */
}

@media (max-width: 768px) {
  .layout-container {
    flex-direction: column;
  }

  .navigation.left,
  .navigation.right {
    height: auto;
    width: 100%;
  }

  .nav-content.vertical,
  .nav-content.horizontal {
    flex-direction: column;
  }

  .nav-menu {
    flex-direction: column;
  }
}
.dropdown {
  position: relative;
  padding-top: 1em!important;
}
.dropdown-menu {
  padding-top: 1em;
}
.dropdown-right .dropdown-menu {
  left: 100%;
  bottom: 17%;
}

.dropdown-left .dropdown-menu {
  right: 100%;
  left: auto;
  bottom: 17%;
}

.dropdown-top .dropdown-menu {
  bottom: 100%;
  top: auto;
}

.nav-link {
  color: #333;
  text-decoration: none;
  padding: 8px 12px;
  display: block;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

 span{
  margin-top: 1em!important;
}
.logout-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #c82333;
}
.modal-content {
  z-index: 10011;
}

.modal-backdrop {
  z-index: 500;
}
.nav-icon {
  list-style-type: none;
  margin: 0;
  display: flex;
}
.innerIcon {
  gap: 0.03em !important;
  padding-block: 0;
    padding-inline: 1em !important;

}
.no-padding {
  padding: 0!important;
  margin: 0!important;
}
.balance-container {
  height: 100%;
 display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-top: 1em;
  margin-inline: 1.3em;
  min-width: 60%;
  max-width: 1000px;

}
.demo-page{
    height: 100vh;
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.demi-body {
  flex: 1;
    border-radius: 10px;
}.nav-logo {
  display: flex;
  align-items: center;
  font-size: 1.2em;
  font-weight: bold;
  position: relative;
}

.logo-edit {
  display: flex;
  align-items: center;
  gap: 0.5em;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #f9f9f9;
}

.logo-edit input {
  font-size: 1em;
  border: none;
  padding: 0.3em;
  border-radius: 3px;
  outline: none;
}

.logo-edit button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.3em 0.6em;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s;
}

.logo-edit button:hover {
  background-color: #0056b3;
}

.logo-edit button + button {
  background-color: #6c757d;
}

.logo-edit button + button:hover {
  background-color: #5a6268;
}

.edit-icon {
  margin-left: 0.5em;
  cursor: pointer;
  transition: color 0.3s;

}
.hide {
  display: none;
}
.w-icon{
  max-width: 1.3em!important;
  max-height: 1.3em;
}
.edit-icon:hover + .hide {
  display: block;
  color: blue;
}
.subscription-button{
  padding: 0!important;
}
.nav-section-left{
  padding-top: 0.7em;
}
@media (max-width: 900px) {
  .navigation {
    padding: 10px;
  }

  .navigation.mobile-view {
    height: auto !important;
    width: 100% !important;
  }
.balance-container{
  min-width: 90%;
}
  .mobile-nav-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
.btn{
  padding-top: 0!important;
}
.dropdown{
  padding-top: 0!important;
}
  .mobile-nav-content.vertical {
    justify-content: space-between;
  }

  .mobile-nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }

  .mobile-nav-header .nav-logo {
    display: flex;
    align-items: center;
  }

  .mobile-nav-header .nav-logo p {
    margin: 0;
    padding-top: 0 !important;
  }

  .mobile-menu-toggle {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
  }

  .mobile-menu {
    margin-top: 10px;
    flex-grow: 1;
    overflow-y: auto;
  }

  .mobile-nav-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .mobile-nav-menu li {
    margin-bottom: 10px;
  }

  .nav-section-right .nav-menu {
    flex-direction: column;
  }

  .logout-button {
    width: 100%;
    margin-top: 10px;
  }

  .mobile-nav-footer {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
  }

  .navigation.left .mobile-nav-content,
  .navigation.right .mobile-nav-content {
    height: 100vh;
  }
}

.desktop-view .nav-content {
  display: flex;
}

.mobile-view .desktop-nav {
  display: none;
}

.desktop-view .mobile-nav {
  display: none;
}.mobile-nav-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.mobile-nav-content.vertical {
  align-items: flex-start;
}

.mobile-nav-content.horizontal {
  align-items: center;
}

.mobile-nav-header {
  display: flex;
  width: 100%;
  padding: 10px;
}

.mobile-nav-header.horizontal {
  justify-content: space-between;
}

.mobile-nav-header.vertical {
  flex-direction: column;
  align-items: center;
}

.mobile-nav-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.mobile-menu {
  width: 100%;
}

.mobile-nav-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.nav-logo {
  display: flex;
  align-items: center;
}